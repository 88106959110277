html, body {
  height: 100%;
  width: 100%;
  overflow: hidden;
  font-family: "Times New Roman";
  scroll-behavior: smooth;
}
* {
    -webkit-overflow-scrolling: touch;
}
p {
  /* font-family: Arial; */
  font-size: 2rem;
}
.App {
  text-align: center;
}
#root {
  height: 100%;
  min-height: 100%;
  width:100%;
  overflow-y: scroll;
}
#home_pg {
  height: 100%;
  overflow-y: scroll;
}
.pg_padding {
  padding: 0 10rem;
}
#twothird {
  text-align: center;
  width: 100%;
  display: inline-block;
  padding: 10px 20px 220px;
  height: 100%;
  min-height: 100%;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}
#twothird .Countdown {
  color: red;
}
#twothird .Countdown .Countdown-col-element strong {
  font-size: 180px;
  
}
#twothird .Countdown .Countdown-col-element span {
  font-size: 20px;
}
.Countdown{margin:0 0 10px;}
.Countdown-col{display:inline-block}
.Countdown-col-element{display:inline-block;margin:0 20px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column}
.Countdown-col-element strong{font-size:50px}

.clear {
  clear: both;
}
#line {
  background-color: black;
  height: 1px;
  width: 100%;
  display: block;
  margin: 5% 0;
}
#twothird #offering {
  width: 100%;
  margin: 80px 0 0px;
  height: 100%;
}
#twothird #offering img {
  width: 55%;
  float: left;
}
#twothird #shares_img {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  padding: 0 40px;
}
#twothird #shares_img img {
  width: 85%;
}
#twothird #offering #caption {
  float: right;
  height: 100%;
  width: 34%;
}
#twothird #offering #caption p {
  text-align: left;
  font-size: calc(10px + 1.5vmin);
}
#twothird #offering #caption p span {
  font-style: italic;
}


#onethird {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 15px 20px;
  height: 100%;
  margin-bottom: 150px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}
#onethird #product_section {
  padding: 0 20px;
  width: 100%;
  height: min-content;
/*  background: #efefef;
*/}
#onethird .purchase_btn {
  border: 1px solid #000;
  padding: 7px 12px;
  float: right;
  margin-top: -5px;
}
#onethird #product_section p {
  text-align: center;
  font-size: calc(10px + 2vmin);
}
#onethird #product_section ul {
  list-style: none;
  padding-inline-start: 0;
}
#onethird #product_section li {
  text-decoration: none;
  border-bottom: 1px solid black;
  padding: 12px 0;
}
#onethird #product_section li:first-child {
  border-top: 1px solid black;
}
#onethird #product_section li.available {

}
#onethird #product_section li.sold {
  pointer-events: none;
  opacity: 0.4;
}
#onethird #product_section li span.stock {
    margin-left: 40%;
    color: #848484;
    margin-right: 6%;
}
.main_menu {
  min-height: 10vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: black;
  background: white;
  position: relative;
  z-index: 9;
  margin-bottom: 100px;
}
#limited {
  padding-bottom: 20px;
}
#limited img{
  width: 25px !important;
  display: flex;
  margin: 0 auto;
  padding: 8px;
  float: none !important;
}
#limited .star img{
  width: 60px !important;
}
#limited .column-left {
  float: left;
  width: 33.333%;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}
#limited .column-right {
  float: right;
  width: 33.333%;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}
#limited .column-center {
  display: inline-block;
  width: 33.333%;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.main_menu ul{
  list-style: none;
  display: inline-block;
  margin: 0;
  width: 100%;
  padding-inline-start: 0;
}
.main_menu ul .right_menu {
  width: 30%;
  text-align: left;
  font-size: 0vh;
  margin-left: 5px;
}
.main_menu ul .right_menu li {
  font-size: calc(10px + 3vmin);
  margin-right: 20px;
}
.main_menu ul li {
  font-size: 9rem;
  display: inline-block;
}
.main_menu ul .right_menu li:hover {
  color: red;
}
.main_menu ul li a:hover {
  color: red;
}
.main_menu ul li a {
  color: black;
  text-decoration: none;
  font-size: calc(10px + 2vmin);
}

#about_pg #tiny_letter_section {
  margin-top: 50px;
}
p.fine_print {
  font-size: 13px;
}
#tiny_letter_section input {
  border: 1px solid #000;
  margin-right: 7px;
  padding: 4px;
}
.history_content:first-child {
  margin-top: -3rem;
}
.history_content {
  width: 100%;
  border-bottom: 1px solid gray;
  padding: 4rem 0;
}
.history_content img {
  width: 100%;
}
.history_content p {
  text-align: left;
  margin-left: 3rem;
  padding-bottom: 2rem;
}


.paypal-button {
  opacity: 0.1;
}
.cover_button {
  border: 1px solid black;
  width: 100px;
  height: 100%;
}
#order_pg {
  margin: 0 auto;
  text-align: center;
}
#paypal_container {
  width: 20%;
  margin: 0 auto;
}
#order_pg img {
  width: 50%;
}

textarea:hover, 
input:hover, 
textarea:active, 
input:active, 
textarea:focus, 
input:focus,
button:focus,
button:active,
button:hover,
label:focus,
.btn:active,
.btn.active
{
    outline:0px !important;
    -webkit-appearance:none;
}
.page_container {
  width: 95%;
  position: relative;
  margin: 0 auto;
  padding-bottom: 50px;
}




/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/
@media (min-width: 1281px) {
    
}

/* 
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/
@media (min-width: 1025px) and (max-width: 1280px) {

}


/* 
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/
@media (min-width: 768px) and (max-width: 1024px) {
    html, body {
    overflow: inherit;
    overflow-x: hidden;
  }
  #twothird {
    text-align: center;
    width: 100%;
    display: block;
    border-right: none;
    padding: 10px 20px 80px;
    overflow-y: scroll;
    height: 90%;
    min-height:90%;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
  }
  #onethird {
    width: 100%;
    display: block;
    padding: 15px 20px;
    float:none;
    height: 16%;
    border-top: 2px solid black;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
  }
  .main_menu:last-child {
    display: none;
  }
  form#main_search {
    height: 100%;
  }
  form#main_search textarea {
    font-size: 5vh;
  }
  form#main_search #submit_button {
    border: 1px solid black;
    height: 25px;
    background: white;
    width: 100%;
  }
  .Countdown-col-element {
    margin: 0 6px;
  }
  .video_container {
    width: 90%;
    margin-bottom: 25px;
  }
  .pg_padding {
    padding: 65px 50px 20px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
  }
  .page_container{
    transform: none;
    position: relative;
    width: 100%;
    max-width: 100%;
  }
  .main_menu ul {
    text-align: center;
  }
  .main_menu ul li {
    padding: 0 10px;
    font-size: calc(10px + 17vmin);
  }
  .main_menu ul .right_menu {
    text-align: center;
    width: 100%;
  }
  
}

/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/
@media (min-width: 481px) and (max-width: 767px) {
  
  
}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/
@media (min-width: 320px) and (max-width: 570px) {
  html, body {
    overflow: inherit;
    overflow-x: hidden;
  }
  .main_menu ul .right_menu {
    text-align: center;
    width: 100%;
    margin-top: 1rem;
  }
  .spacer {
    height: 0vh;
  }
  .Countdown-col-element {
    margin: 0 6px;
  }
  #twothird #offering img {
    float: none;
  }
  #twothird #offering #caption {
    float: none;
    width: 100%;
    padding-left: 20px;
  }
  #twothird {
    text-align: center;
    width: 100%;
    display: block;
    border-right: none;
    padding: 10px 20px 20px;
    overflow-y: hidden;
    height: auto;
    min-height: auto;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
  }
  #onethird {
    width: 100%;
    display: block;
    padding: 15px 20px;
    float:none;
    height: auto;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
  }
  #twothird #offering img {
    width: 100%;
  }
  .main_menu:last-child {
    display: none;
  }
  form#main_search {
    height: 100%;
  }
  form#main_search textarea {
    font-size: 5vh;
  }
  form#main_search #submit_button {
    border: 1px solid black;
    height: 25px;
    background: white;
    width: 100%;
  }
  .video_container {
    width: 90%;
    margin-bottom: 25px;
  }
  .pg_padding {
   padding: 20px 40px;
  }
  .page_container{
    transform: none;
    position: relative;
    width: 100%;
    left: 0;
    top: 0;
    max-width: 100%;
  }
  .main_menu ul {
    text-align: center;
  }
  .main_menu ul li {
    font-size: calc(10px + 17vmin);
    padding: 0 10px;
  }
  p {
    font-size: 1rem;
  }
  #onethird #product_section {
    padding: 0;
  }
  #onethird #product_section li span.stock {
    margin-left: 32%;
    margin-right: 3%;
  }
  #twothird #shares_img {
      padding: 0;
  }
  #twothird #shares_img img {
    width: 100%;
  }
  #twothird .Countdown .Countdown-col-element strong {
    font-size: 60px;
  }
  #line {
    margin: 15% 0;
  }
  #paypal_container {
    width: 100%;
  }
  .main_menu {
    margin-left: 0;
    margin-top: 50px;
    margin-bottom: 55px;
  }
   #paypal_container img {
    width: 100%;
  }
  .history_content {
    width: 100%;
  }
  .history_content p{
    margin-left: 0;
  }
}
@media (min-width: 300px) and (max-width: 320px) {
  .spacer {
    height: 0vh;
  }
  #onethird #product_section li span.stock {
      margin-left: 24%;
      margin-right: 2%;
  }

}
